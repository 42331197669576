.drop-down{
  position: relative;
  float: left;
  width: 100%;
}
.drop-down .drop-down-box {
  position: relative;
  float: left;
  width: calc(100% - 2em);
  padding: 0 1em;
  margin-left: 1em;
  display: block;
}

.drop-down .drop-down-box  > ul > li {
  position: relative;
  float: left;
  width: 100% !important;
  margin-bottom: 15px !important;
  transition-property: all;
  transition-duration: 3s;
  transition-timing-function: cubic-bezier(0.55,0,0.1,1);
}

.drop-down .drop-down-box  > ul > li:last-child { margin-bottom: 0; }

.drop-down .drop-down-box  > ul > li a, .drop-down .drop-down-box  > ul > li span {
 display: block;
 text-transform: uppercase;
 font-size: 13px;
 line-height: 19px;
 opacity: .8;
 color: #555 !important;
 font-weight: 500;
}

.drop-down .drop-down-box  > ul > li span{
  position: relative;
  float: left;
  width: calc(100% - 2em);
  margin: 1em 1em .8em 1em;
}

.drop-down .drop-down-box  > ul > li a:hover { text-decoration: underline; }

.drop-down .drop-down-box  > ul > li a i {
  color: #555 !important;
  display: block;
  float: left;
  line-height: 15px;
  font-size: 17px;
  margin-right: 10px;
}
.drop-down .drop-down-box  > ul > li .sub-menu {padding-left: 2.5em; padding-top: 1em; 
    margin-top: 1em;}
.drop-down .drop-down-box  > ul > li .sub-menu li {margin-top: 10px;}
.drop-down .drop-down-box  > ul > li .sub-menu li a { color: #8E9195; }
.drop-down .drop-down-box:before {
  display: block;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #FFF;
  position: absolute;
  top: -6px;
  left: 50%;
  margin-left: -6px;
}

.drop-down .icon-open{
  width: 19px;
  margin-right: 5px;
}

.icon-book{
  color: #353535 !important;
    margin-right: .5em;
    top: 3px;
    position: relative;
}

@media only screen and (min-width: 46.001em)   {

.drop-down .drop-down-box{display: none;} 


.drop-down .drop-down-box  > ul > li span{
  position: relative;
  float: left;
  width: 100%;
  margin: auto;
  color: #34c588 !important;

}

.drop-down .drop-down-box {
  position: absolute;
  top: 34px;
  left: 50%;
  margin-left: -143px;
  width: 287px;
  padding: 30px;
  background: #FFF;
  border-radius: 5px;
  box-shadow: 0 0 44px rgba(0,0,0,0.15);
}
  .icon-book{color: #33c586 !important; margin-right: .5em;}
  .header .menu .drop-down .drop-down-box  > ul > li a{color:#33c587 !important};

.drop-down .drop-down-box  > ul > li a, .drop-down .drop-down-box  > ul > li span {
 display: block;
 text-transform: uppercase;
 font-size: 15px !important;
 line-height: 19px;
 opacity: .9;
 color: #4CC89A !important;
 font-weight: 500;
}
.drop-down .drop-down-box  > ul > li a i {
  color: #4CC89A !important;
  display: block;
  float: left;
  line-height: 15px !important;
  font-size: 17px;
  margin-right: 10px;
}
.drop-down .drop-down-box  > ul > li a, .drop-down .drop-down-box  > ul > li span{font-size: 15px;}
}




