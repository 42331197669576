@charset "UTF-8";

/* * {
  font: 500 16px/25px 'Maison Neue', sans-serif;
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  text-align: left;
  border: 0;
  color: #AAAAAA;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  outline: 0;
} */

body {
  background: #fff;
  overflow-x: hidden;
}

h1 {
  font-size: 30px;
  line-height: 30px;
}

h2 {
  font-size: 26px;
  line-height: 26px;
}

h3 {
  font-size: 18px;
  line-height: 18px;
}

h4 {
  font-size: 16px;
  line-height: 16px;
}

h5 {
  font-size: 15px;
  line-height: 15px;
}

h6 {
  font-size: 12px;
  line-height: 12px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  color: #353A42;
}

.gray-bg { background: #F7F6F6; }
.orange-bg { background: #F87551; }

.image-left { float: left; }
.image-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.image-right { float: right; }

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.white-space {
  white-space: nowrap;
}

*::selection {
  background: #27CDAE;
  color: #fff;
}

*::-moz-selection {
  background: #27CDAE;
  color: #fff;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

b, strong {
  font-weight: 600;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.relative {
  position: relative;
}

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

img {
  vertical-align: middle;
}