/* http://prismjs.com/download.html?themes=prism&languages=markup+css+clike+javascript */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
 code[class*="language-"],
 pre[class*="language-"] {
 	color: black;
 	background: none;
 	text-shadow: 0 1px white;
 	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
 	text-align: left;
 	white-space: pre;
 	word-spacing: normal;
 	word-break: normal;
 	word-wrap: normal;
 	line-height: 1.5;

 	-moz-tab-size: 4;
 	-o-tab-size: 4;
 	tab-size: 4;

 	-webkit-hyphens: none;
 	-moz-hyphens: none;
 	-ms-hyphens: none;
 	hyphens: none;
 }

 pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
 code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
 	text-shadow: none;
 	background: #b3d4fc;
 }

 pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
 code[class*="language-"]::selection, code[class*="language-"] ::selection {
 	text-shadow: none;
 	background: #b3d4fc;
 }

 @media print {
 	code[class*="language-"],
 	pre[class*="language-"] {
 		text-shadow: none;
 	}
 }

 /* Code blocks */
 pre[class*="language-"] {
 	overflow: auto;
 }

 :not(pre) > code[class*="language-"],
 pre[class*="language-"] {
 	background: #FFF;
 }

 /* Inline code */
 :not(pre) > code[class*="language-"] {
 	border-radius: .3em;
 	white-space: normal;
 }

 .token.comment,
 .token.prolog,
 .token.doctype,
 .token.cdata {
 	color: slategray;
 }

 .token.punctuation {
 	color: #999;
 }

 .namespace {
 	opacity: .7;
 }

 .token.property,
 .token.tag,
 .token.boolean,
 .token.number,
 .token.constant,
 .token.symbol,
 .token.deleted {
 	color: #905;
 }

 .token.selector,
 .token.attr-name,
 .token.string,
 .token.char,
 .token.builtin,
 .token.inserted {
 	color: #690;
 }

 .token.operator,
 .token.entity,
 .token.url,
 .language-css .token.string,
 .style .token.string {
 	color: #a67f59;
 	background: hsla(0, 0%, 100%, .5);
 }

 .token.atrule,
 .token.attr-value,
 .token.keyword {
 	color: #07a;
 }

 .token.function {
 	color: #DD4A68;
 }

 .token.regex,
 .token.important,
 .token.variable {
 	color: #e90;
 }

 .token.important,
 .token.bold {
 	font-weight: bold;
 }
 .token.italic {
 	font-style: italic;
 }

 .token.entity {
 	cursor: help;
 }



 pre.line-numbers {
 	position: relative;
 	padding-left: 3.8em;
 	counter-reset: linenumber;
 }

 pre.line-numbers > code {
 	position: relative;
 }

 .line-numbers .line-numbers-rows {
 	position: absolute;
 	pointer-events: none;
 	top: 0;
 	font-size: 100%;
 	left: -3.8em;
 	width: 3em; /* works for line-numbers below 1000 lines */
 	letter-spacing: -1px;
 	border-right: 1px solid #999;

 	-webkit-user-select: none;
 	-moz-user-select: none;
 	-ms-user-select: none;
 	user-select: none;

 	background: #f2feef;
 	border-radius: 2px 0 0 2px;

 }

 .line-numbers-rows > span {
 	pointer-events: none;
 	display: block;
 	counter-increment: linenumber;
 }

 .line-numbers-rows > span:before {
 	content: counter(linenumber);
 	color: #5dc79e;
 	display: block;
 	padding-right: 0.8em;
 	text-align: right;
 }