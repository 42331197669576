footer.application-footer{
	position: relative;
	float: left;
	width: 100%;
	padding: 0 0 3em 0;
	color: #555;
	border-top: 2px solid #eee;
}

footer.application-footer  p{
	position: relative;
	float: left;
	width: auto;
	font-size: 0.7em;
}

footer.application-footer ul, footer.application-footer li{
	position: relative;
	float: left;
	width: 100%;
	list-style-type: none;
}

footer.application-footer .ft-social{
	position: relative;
	float: left;
	margin: auto;
}

footer.application-footer .ft-social a{ 
	position: relative;
	float: left;
	margin:auto;
	margin: 0 .25em;
}
footer.application-footer .ft-social svg{
	position: relative;
	float: left;
	width: 44px;
	height: 44px;
	padding: 12px;
	fill: rgba(38, 38, 38, 0.35);
	border-radius: .2em;
	background: #eeeeee;
}

footer.application-footer .ct{
	position: relative;
	float: left;
	width: 100%;
	display: -webkit-flex;
	display: -o-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: flex;
	-webkit-flex-direction: row;
	-o-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: flex-start;
	-o-justify-content: flex-start;
	-moz-justify-content: flex-start;
	-ms-justify-content: flex-start;
	justify-content: flex-start;
}


.ft-content{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	padding: 2em 0;
}

.ft-content	svg{
	position: absolute;
	top: 0;
	right: .8em;
	fill:#555;
	width: 16px;
	height: 16px;
	margin: 19px 0;
	opacity: .6;
	-webkit-transition: all 0.25s ease-out 0s;
	-moz-transition: all 0.25s ease-out 0s;
	-o-transition: all 0.25s ease-out 0s;
	transition: all 0.25s ease-out 0s;
}

.ft-content	a{
	position: relative;
	float: left;
	width: calc(100% - 2.5em);
	padding: .5em 1.25em;
	line-height: 1.5em;
	text-align: left;
	font-size: 0.75em;
	letter-spacing: .25px;
	font-size: 12px;
	-webkit-transition: all 0.25s ease-out 0s;
	-moz-transition: all 0.25s ease-out 0s;
	-o-transition: all 0.25s ease-out 0s;
	transition: all 0.25s ease-out 0s;
	font-weight: bold;
	color: #555;
	opacity: .7;
}
.ft-content	a:hover{
	opacity:1;
	text-decoration: underline;
}

.ft-content	p{
	font-weight: bold;
	letter-spacing: 1px;
	font-size: 0.76em;
	opacity: 1;
	color: #555;
}

.ft-content	label{
	position: relative;
	float: left;
	font-size: 12px;
	width: calc(100% - 2.5em);
	padding: 0 1.25em;
	font-weight: 500;
}

.ft-content	ul{
	margin:.3em 0;
	height: 55px;
	-webkit-transition: all 0.25s ease-out 0s;
	-moz-transition: all 0.25s ease-out 0s;
	-o-transition: all 0.25s ease-out 0s;
	transition: all 0.25s ease-out 0s;
	overflow: hidden;
	width: calc(100% - 2px);
	border: 1px solid transparent;
}

.ft-content	li.li-header{
	height: 54px;
	padding: 11px 0;
}
.ft-content	li{overflow: hidden;}

.ft-content	li.li-header p, .ft-content	li.li-header label{
	line-height: 16px;
	height: auto;
}

.ft-content ul.expand{
	height: auto;
	overflow: auto;
	border-radius: .2em;
	padding-bottom: 1em;
	box-shadow: 0 .2em 1em #eee;
	border:1px solid #eee;
}

.ft-content ul.expand label{display: none;}

.ft-content ul.expand li.li-header p{line-height: 20px;}

.ft-content ul.expand svg{
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	margin: 12px 0;
}

.ft-content p{
	width: calc(100% - 2.5em);
	padding: 0 1.25em;
	text-align: left;
	cursor: pointer;
	border-radius: .2em;
	height: 100%;
}

footer .footer-logo{
	position: relative;
	float: left;
	width: 100%;
	fill:#555;
	margin-bottom: .6em;
}

footer .footer-logo svg{
	position: relative;
	float: left;
	width: 120px;
	margin: 0 calc(50% - 60px);
}
footer .footer-logo p.footer-rights{
	position: relative;
	float: left;
	line-height: 24px;
	font-size: 14px;
	color: #555;
    font-size: 12px;
    line-height: 16px;
    margin: .5em 0;
    text-align: center;
    width: 100%;
    opacity: .6;
}

@media only screen and (min-width: 46.001em)   {

	footer.application-footer .ct.ft-social{display: block;}

	footer.application-footer .ct.ft-social a{margin:0 .25em 0 0;}

	footer.application-footer{padding: 4em 0 4.5em 0;}

	footer .footer-logo p.footer-rights{text-align: left;}

	footer .footer-logo svg{margin: 0;}
	
	footer.application-footer .ft-content{
		width: calc(100% - 300px);
		float: right;
		padding: 0;
	}

	footer.application-footer .ft-content svg, footer.application-footer .ft-content label{ display: none; }
	footer.application-footer .ft-content a{ width: auto; }

	footer.application-footer .ft-content ul{
		margin:0;
		height:auto;
		overflow:auto;
		width: 25%;
		border: none;
	}

	footer.application-footer .ft-content p{
		font-size: 0.8em;
		cursor: default;
		height: auto;
	}

	footer.application-footer .ft-content li.li-header{
		height: auto;
		padding: 0 0 .5em 0;
	}

	footer.application-footer .ft-content ul.expand{
		height: auto;
		overflow: auto;
		padding: auto;
	}
	footer.application-footer .ft-content li.li-header{
		height: auto;
		padding: .5em 0;
		box-shadow:none;
		border:none;
	}

	footer.application-footer .ft-content li.li-header p{line-height: 16px;}

	footer.application-footer .ft-actions{
		position: relative;
		float: left;
		width: 240px;
	}
}




/*.footer-legal{
	position: relative;
	float: left;
	width: 100%;
	display: none;
	display: -webkit-flex;
	display: -o-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: flex;
	-webkit-flex-direction: row;
	-o-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-o-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: flex-start;
	-o-justify-content: flex-start;
	-moz-justify-content: flex-start;
	-ms-justify-content: flex-start;
	justify-content: flex-start;

	p,a{
		font-size: 12px;
		line-height: 16px;
		font-family: sans-serif;
		font-weight: 500;
		color: rgba(38, 38, 38, 0.75);
	}
	p{width: 100%; text-align: center;}

	ul{
		width: auto;
		margin: .5em auto;

		li{
			position: relative;
			float: left;
			width: auto;
			padding-right: 1.5em;
		}
	}

	a{font-weight: 600;}

	img{
		position: relative;
		float: right;
		width: auto;
		height: 12px;
		margin: 3px 0 1px .5em;
	}
	}*/

	

