@keyframes typing {
    from {
        width: 0;
    }
}
@keyframes blink-caret {
    50% {
        border-color: transparent;
    }
}

@keyframes mail-change {
    0% {
     -webkit-transform: rotate(5deg);
     -moz-transform: rotate(5deg);
     -ms-transform: rotate(5deg); 
     -o-transform: rotate(5deg); 
     transform: rotate(5deg); 
 }

 50% {
     -webkit-transform: rotate(-5deg);
     -moz-transform: rotate(-5deg);
     -ms-transform: rotate(-5deg); 
     -o-transform: rotate(-5deg); 
     transform: rotate(-5deg); 
 }

 100% {
     -webkit-transform: rotate(5deg);
     -moz-transform: rotate(5deg);
     -ms-transform: rotate(5deg); 
     -o-transform: rotate(5deg); 
     transform: rotate(5deg); 
 }
}

.try-now {
    width: 100%;
    float: right;
}

.card-form {
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, .07), 0 3px 8px 0 rgba(0, 0, 0, .1);
    border-radius: 5px;
    font-size: 16px;
    padding: 2px 12px;
}

.try-now .head {
    border-radius: 5px 5px 0 0;
    padding: 12px 14px;
    background: #424242;
    margin: 0;
}

.try-now .card-form-success .box-mail-content {
    padding: 20% 1em !important;
}

.try-now .box-mail-content {
    position: relative;
    float: left;
    width: 100%;
    padding: 2em 1em;
}

.try-now .box-mail-content svg {
    position: relative;
    float: left;
    width:75px;
    height: auto;
    margin: 0 calc(50% - 37.5px) 1em calc(50% - 37.5px);
    animation: mail-change 2s infinite alternate;
}

.try-now .box-mail-content p {
    position: relative;
    float: left;
    line-height: 20px;
    width: 100%;
    font-size: 15px;
}

.try-now .head svg{
    position: relative;
    float: right;
    margin-left: 1em;
    fill:white;
    width: 24px;
    height: 24px;
    margin-top: -5px;
    -moz-transform:scale(.9);
    -webkit-transform:scale(.9);    
    -ms-transform:scale(.9);
    -o-transform:scale(.9);
    transform:scale(.9);
    opacity: .85;
}

.try-now .head svg.left{float: left; margin-left: 0;}

.try-now .head img.close {
    top: 3px;
    position: relative;
}

.try-now .head img.right {
    float: right;
    margin: 0 4px;
    opacity: 0.6;
    -webkit-user-drag: none;
}

.card-form .row {
    border-bottom: 1px solid #ededed;
    display: flex;
    flex-direction: row;
    padding: 12px 20px;
}

.card-form .row:last-child {
    text-align: right;
    border: none;
    flex-direction: unset;
    align-items: flex-end;
    padding: 16px 24px;
}

.card-form-success .row:first-child {
    display: none;
}
.card-form-success .row {
    height: 295px;
}
.card-form-success .row:last-child {
    display: none;
}

.card-form label {
    display: inline-block;
    width: 130px;
    color: #000;
    font-weight: 600;
    flex-shrink: 0;
}

.card-form input:not(.submit),
.card-form label {
    font-size: 15px;
    line-height: 16px;
    outline: none;
}

.card-form .email {
    font-family: sans-serif;
    font-size: 14px;
    color: #000;
    -webkit-font-smoothing: auto;
}

.card-form input {
    border: none;
    background: none;
    width: 100%;
}

h4 {
    color: white;
    width: auto;
}

.card-form a.button, 
.card-form button {
    background: #4285f4;
    box-shadow: 0 0 2px rgba(0,0,0,.12), 0 2px 4px rgba(0,0,0,.24);
    border-bottom: 1px solid #098dd5;
    border-radius: 4px;
    font-size: 15px;
    text-align: center;
    color: #fff;
    font-weight: 500;
    padding: 0 20px;
    line-height: 2.8;
    cursor: pointer;
    outline: none;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
}

.card-form a.button:disabled, 
.card-form button:disabled {
    background: #999;
    border-bottom: 1px solid #ccc;
    color: #333;
}

.card-form input.email-typing {
    border-right: .1em solid;
    /* fallback */
    width: 20ch;
    /* # of chars */
    white-space: nowrap;
    overflow: hidden;
    animation: typing 5s steps(21, end), /* # of steps = # of chars */
    blink-caret .5s step-end infinite alternate;
}

.card-form input.email-typing:focus {
    border-right: none;
    animation: none; 
}
.card-form input.email-typing:active {
    border-right: none;
    animation: none; 
}


@media only screen and (min-width: 46.001em)   {

    .try-now {
        width: 458px;
    } 

    .try-now .box-mail-content svg {
       margin: 0;
       margin-right: 15px;
   }
   .try-now .box-mail-content p {
    width: calc(100% - 110px);
    line-height: 18px;
}


}
