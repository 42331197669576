ul.contact{
	position: relative;
	float: left;
	width: 100%;
	line-height: 24px;
	font-size:14px;
	margin-top: 4em;
}

ul.contact li{
	position: relative;
	float: left;
	width: 160px;
	height: auto;
	margin: .5em calc(50% - 80px);	
}

ul.contact li a{
	position: relative;
	float: right;
	width: calc(100% - 30px);
	opacity: 1 !important;
	color: #555;
}

ul.contact li svg{
	position: relative;
	float: left;
	width: 24px;
	fill: #555;
	opacity: .7;
}

form .row .first {
	padding-left: 15px;
	padding-right: 15px;
}

form .row .last {
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 1em;
}

@media only screen and (min-width: 46.001em)   { 

	form .row .last {
		margin-top: auto;
		padding-left: 10px;
	}

	form .row .first {
		padding-right: 10px;
	}

}


form .input-row {
	position: relative;
	margin-bottom: 15px;
}

form .input-row	label {
	color: #555;
	display: block;
	font-size: 16px;
	line-height: 16px;
	margin-bottom: 6px;
}

form .input-row	input.error {
	border: solid 1px #B71C1C;
}

form .input-row	label.error {
	display: block;
	font-size: 14px;
	line-height: 11px;
	color: #ff4e4a;
	white-space: nowrap;
	margin: 3px 0 0;
}

form .input-row .input-text {
	width: 100%;
	height: 42px;
	padding: 5px 15px;
	border-radius: 2px;
	background: rgba(0,0,0,0.08);
	color: #000;
	-webkit-transition: all 0.15s ease-out 0s;
	-moz-transition: all 0.15s ease-out 0s;
	-o-transition: all 0.15s ease-out 0s;
	transition: all 0.15s ease-out 0s;
}

form .input-row .input-text:focus { background: rgba(0,0,0,0.1); }

form .button-bar {
	margin-top: 20px;
}

form .button-bar .button {
	width: 100%;
	font-size: 16px;
	box-shadow: 0 2px 14px rgba(0,0,0,0.08);
	/*background-color: #00ccb0;*/
	color: white;
}
