/* nfeio */
@font-face {
  font-family: "nfeio";
    src: url("../../fonts/1501462340.eot");
    src:url("../../fonts/1501462340.eot") format("embedded"),
    url("../../fonts/1501462340.woff") format("woff"),
    url("../../fonts/1501462340.ttf") format("truetype");
    font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "nfeio" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "nfeio" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\61";
}
.icon-book:before {
  content: "\62";
}
.icon-code:before {
  content: "\63";
}
.icon-digital-certificate:before {
  content: "\64";
}
.icon-exclamation:before {
  content: "\65";
}
.icon-list:before {
  content: "\67";
}
.icon-status:before {
  content: "\68";
}
.icon-facebook:before {
  content: "\69";
}
.icon-twitter:before {
  content: "\6a";
}
.icon-google:before {
  content: "\6b";
}
.icon-file:before {
  content: "\66";
}
.icon-arrow-left:before {
  content: "\6e";
}
.icon-arrow-right:before {
  content: "\6f";
}
.icon-pdf:before {
  content: "\6c";
}
.icon-arrow-left-2:before {
  content: "\6d";
}
.icon-arrow-right-2:before {
  content: "\70";
}
.icon-logo-nfeio:before {
    font-size: 100px;
    line-height: 0.485;
  content: "\71";
}
.icon-open {
    position: relative;
    float: left;
    width: 20px;
    height: 20px;
    background: url(../../img/menu/open-source-mobile.svg);
    background-position: center;
    background-size: 18px 18px;
    background-repeat: no-repeat;
}

.icon-settings {
  position: relative;
  float: left;
  width: 20px;
  height: 20px;
  background: url(../../img/menu/settings-mobile.svg);
  background-position: center;
  background-size: 18px 18px;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 46.001em) {
  .icon-open {
      position: relative;
      float: left;
      width: 20px;
      height: 20px;
      background: url(../../img/menu/open-source.svg);
      background-position: center;
      background-size: 18px 18px;
      background-repeat: no-repeat;
  }

  .icon-settings {
    position: relative;
    float: left;
    width: 20px;
    height: 20px;
    background: url(../../img/menu/settings.svg);
    background-position: center;
    background-size: 18px 18px;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 46.001em)   { 
.icon-open{
	position: relative;
    float: left;
    width: 20px;
    height: 20px;
    background: url(../../img/menu/open-source.svg);
    background-position: center;
    background-size: 18px 18px;
    background-repeat: no-repeat;
}
}