.box-prices { padding: 100px 0; }
.box-prices	.container { max-width: 1000px; }
.box-prices	.title { margin-bottom: 63px; }
.box-prices	.title	h2 { margin-bottom: 3px; }

.box-prices	.box-list-prices .box-price {
	border-radius: 3px;
	padding: 50px;
}

.box-prices	.box-list-prices .box-price.box-white {
	background: #FFF;
	box-shadow: 0 2px 34px rgba(0,0,0,0.09);
}

.box-prices	.box-list-prices .box-price hr {
	height: 1px;
	background: #E9E9E9;
	margin: 22px auto;
	display: block;
	width: 53px;
}

.box-prices	.box-list-prices .box-price h5 {
	text-align: center;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	color: #F56F5B;
	letter-spacing: 0.64px;
	margin-bottom: 1px;
	text-transform: uppercase;
}
.box-prices	.box-list-prices .box-price p {
	display: block;
	text-align: center;
	font-weight: 500;
	font-size: 17px;
	color: #9B9B9B;
	line-height: 27px;
}

.box-prices	.box-list-prices .box-price span {
	display: block;
	text-align: center;
	font-weight: 500;
	font-size: 35px;
	color: #353A42;
}

.box-prices	.box-list-prices .box-price span i {
	display: block;
	text-align: center;
	font-weight: 500;
	font-size: 14px;
	color: #9B9B9B;
	line-height: 14px;
	margin-top: 10px;
}


.box-prices	.view-more {
	font-weight: 500;
	font-size: 16px;
	color: #AAAAAA;
	line-height: 25px;
	display: block;
	text-align: center;
	margin-top: 72px;
}


.box-prices	.view-more	a {
	color: #E2508D;
	border-bottom: 1px dotted #F1C6D8;
	padding-bottom: 3px;
}