.title h3,
.title h2 {
    text-transform: uppercase;
    font-size: 36px;
    line-height: 36px;
    color: #353A42;
    letter-spacing: 2.09px;
    margin-bottom: 19px;
    font-weight: 400;
}

.title p {
    display: block;
    line-height: 25px;
}

.title p a {
    line-height: 25px;
    color: #1683AF;
}

.box-3 .title .title-cta,
.box-12 .title .title-cta {
    color: #03a15d;
    opacity: .7;
}

.box-7 .title .title-cta {
	display: block;
	height: 50px;
	margin-top: 23px;
	color: #03a15d;
}