.header {
	background-image: none;
	background-size: 100% 60px;
	background-repeat: no-repeat;	
	height: 60px;
	z-index: 100;
	position: relative;
	background: #36c690;
}

.header .col-md-3{
	position: relative;
	float: left;
	width: auto;
}

.header  .col-md-9{
	position: relative;
	float: right;
	width: auto;
}

.header	.group-header {
	width: 1100px;
	left: calc(50% - 550px);
	position: relative;
	z-index: 2;
	float: left;
	padding: 1.3em 0;
}

@media only screen and (max-width: 1100px)   { 

	.header	.group-header {
		width: 100%;
		left:0;
	}
}

.header	.group-header.fixed {
	/*padding: 15px 48px;*/
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background: #FFF;
	box-shadow: 0 2px 24px rgba(0,0,0,0.07);
}

.header	.group-header .logo i { color: #555555; }

.header	.group-header.fixed .logo i { color: #555;}


.header	.group-header .menu>ul>li.login a {border-color: #AAA;}
.header .group-header.fixed .menu>ul>li>a{color:  #555 !important;}
.header .group-header.fixed .menu>ul>li.login a{border-color:#555;}

.header .group-header.fixed .menu>ul>li.login a:hover{background: #555;}

.header	.group-header .menu>ul>li.login a:hover {
	background: #AAA;
	color: #FFF;
	text-decoration: none;
}

.header	.group-header.fixed .btMenu svg{fill:#555;}
.header	.logo {
	display: block;
}

.header	.group-header .logo i {
	display: block;
	width: 100px;
	height: auto;
	font-size: 100px;
	color: #fff;
}

.header	.group-header .logo i:before {
	display: block;
	line-height: 47px;
}

.header	.menu {
	position: fixed;
	width: 75vw;
	background: white;
	left: 120vw;
	top: 0;
	height: 100vh;
	z-index: 300;
	box-shadow: -1em 0 1em rgba(0, 0, 0, 0.45);
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}

.header	.menu:before{
	content: attr(data-content) '';
	position: fixed;
	width: 25vw;
	top: 0;
	bottom: 0;
	left: -25vw;
	pointer-events: none;
	background: none;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}

.header	.menu.on{left: 25vw;}
.header	.menu.on:before{
	background: rgba(47, 47, 47, 0.95);
	pointer-events:all;
	left: 0;
}

.header	.menu li{
	position: relative;
	float: left;
	width: 100%;
	margin: .5em 0 !important;
}

.header	.menu li a{
	position: relative;
	float: left;
	width: auto;
	margin: 0 1em;
	font-size: 1em;
	color: #51cbff;
}

.btClose{
	position: relative;
	float:right;
	width: 100%;
	height: 3em;
	background: none;
}

.btClose:active, .btClose:hover{background: none;}

.btClose svg{
	position: absolute;
	right: 0;
	fill: #353A42;
	width: 24px;
	height: 24px;
	margin:calc(1.5em - 36px) 1em;
}
.header .menu>ul>{top: 3em;}
.header .menu>ul>li>a{font-size: 1em;}

.header .menu li.login a{
	width: auto;
	margin: 0 1em;
	left: 1em;

}

.header .menu>ul>li.login a:hover {
	/*background: #51cbff !important;*/
	/*color: #51cbff !important;*/
	background: #555 !important;
	color: #fff !important;
	text-decoration: none;
}
@media only screen and (min-width: 46.001em)   { 
	.header	.menu {
		padding: 9px 0;
	}
	.header .menu>ul>li.login a:hover {
	background: #51cbff !important;
	color: white !important;
}
}


.header	.group-header .menu:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.header	.menu * html & { height: 1px }
.header .menu> ul { float: right; }

.header .menu>ul>li {
	float: left;
	margin-left: 8px;
	position: relative;
}

.header .menu>ul>li.login a {
	border-color: #555 !important;
}



.header .menu>ul>li:first-child { margin-left: 0; }

.header .menu>ul>li>a{
	padding: 6px 12px;
	border: 2px solid transparent;
	display: block;
	font-size: 13px;
	color: #555 !important;
	letter-spacing: 0.49px;
	line-height: 13px;
	text-transform: uppercase;
	border-radius: 60px;
	-webkit-transition: all 0.15s ease-out 0s;
	-moz-transition: all 0.15s ease-out 0s;
	-o-transition: all 0.15s ease-out 0s;
	transition: all 0.15s ease-out 0s;
}

.header .menu>ul>li>a:hover { text-decoration: underline; }

.mobile-header{
	position: relative;
	float: left;
	width: calc(100% - 2em);
	padding: 1.5em 1em;
	height: auto;
	display: block;
	z-index: 100;
}

.mobile-header{
	position: relative;
	float: left;
	width: 100%;
	display: block;
}

.mobile-header .logo{
	position: relative;
	float: left;
	height: 100%;
	width: auto;
	fill:white;
}

.btMenu{
	position: absolute;
	right: 0;
	top: .75em;
	background: none;
}
.btMenu svg{
	position: relative;
	float: left;
	fill:white;
	width: 24px;
	height: 24px;

}
.mobile-header .logo svg{
	position: relative;
	float: left;
	width: 85px;
	height: auto;
}

@media only screen and (min-width: 46.001em)   { 
	.header{
		height: 97px;
		display: block;
		background: none;
	}
	.btMenu{display: none;}
	.header .menu {display: block;}

	.header	.menu {
		position: relative;
		width: auto;
		background: none;
		left: auto;
		top: auto;
		height: auto;
		z-index:auto;
		box-shadow: none;
		-webkit-transition: none;
		-moz-transition:none;
		-o-transition: none;
		transition: none;
	}

	.header	.menu:before{
		content: attr(data-content) '';
		position: relative;
		float: left;
		width: auto;
		top: auto;
		bottom: auto;
		left: auto;
		pointer-events: none;
		background: none;
		-webkit-transition: none;
		-moz-transition:none;
		-o-transition: none;
		transition: none;
	}


.header	.menu li{
	position: relative;
	width: auto;
	margin: 0 !important;
}

.header	.menu li a{
	position: relative;
	float: left;
	width: auto;
	margin: 0 ;
}
.header .menu>ul>li a{color: white !important;}
.header .menu>ul>li.login a{color: white !important;}
.header .menu>ul>li.login a{border-color: white !important;}

.header .group-header.fixed .menu>ul>li.login>a{border-color:  #555 !important;}
.header .group-header.fixed .menu>ul>li.login>a:hover{background: #555 !important;}
.btClose{display: none;}

.header .menu .select-sandbox #sand{
	width: 110px;
}

.header .menu .select-sandbox #sand:hover{
	text-decoration: none;
}

}