@-webkit-keyframes show-1 {
  0% {
    opacity: 0;
    -moz-opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=00)";
    transform: translate3d(0, 5px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    -moz-opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
}

@-moz-keyframes show-1 {
  0% {
    opacity: 0;
    -moz-opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=00)";
    transform: translate3d(0, 5px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    -moz-opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
}

@-o-keyframes show-1 {
  0% {
    opacity: 0;
    -moz-opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=00)";
    transform: translate3d(0, 5px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    -moz-opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
}

@keyframes show-1 {
  0% {
    opacity: 0;
    -moz-opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=00)";
    transform: translate3d(0, 5px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    -moz-opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
}


@keyframes stretch-paper {
  0% {
   -ms-transform: rotate(7deg); /* IE 9 */
   -webkit-transform: rotate(7deg); /* Chrome, Safari, Opera */
   transform: rotate(7deg);
 }

 100% {
   -ms-transform: rotate(-7deg); /* IE 9 */
   -webkit-transform: rotate(-7deg); /* Chrome, Safari, Opera */
   transform: rotate(-7deg);
 }
}

@keyframes explosion-point {
  0% { stroke-width: 0;  stroke-opacity: 0.5;}
  25% { stroke-width: 25;  stroke-opacity: 0;}
  50% { stroke-width: 0;  stroke-opacity: 0;}
  75% { stroke-width: 0;  stroke-opacity: 0.5;}
  100% { stroke-width: 25;  stroke-opacity: 0;}
}

@keyframes dash-progress {

 0% {
  stroke-dashoffset: 0;
}

100% {
  stroke-dashoffset: 100;
}
}

@-webkit-keyframes pulse {
  0%,40% { transform: translateY(0); }
  70% { transform: translateY(3px); }
}
@-moz-keyframes pulse {
  0%,40% { transform: translateY(0); }
  70% { transform: translateY(3px); }
}
@-o-keyframes pulse {
  0%,40% { transform: translateY(0); }
  70% { transform: translateY(3px); }
}
@keyframes pulse {
  0%,40% { transform: translateY(0); }
  70% { transform: translateY(3px); }
}