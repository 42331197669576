.menu-mobile ul{
	position: relative;
	float: left;
	width: 100%;
	overflow-y: auto;
	height: calc(100% - 48px);
}

.menu-mobile ul li{
	position: relative;
	float: left;
	width: calc(100% - 3em);
}
.menu-mobile ul li a{ 
	position: relative;
	float: left;
	width: calc(100% - 3em);
	padding: .7em 1.5em;
}

.menu-mobile ul li.li-sub{
	width:100%;
	padding: .7em 1.5em;
}

.menu-mobile ul li.li-sub p{
	position: relative;
	float: left;
	width: auto;
}

.menu-mobile ul.inside li{
	width: 100%;
}

.menu-mobile ul li.li-sub svg{
	position: relative;
	float: left;
	width: 20px;
	height: 20px;
	margin: 2px 5px;
	fill:rgba(0,0,0,0.2);
	display: none;
}

.menu-mobile ul li a.action{
	border:2px solid #b7b7b7;
	margin: 1em 1.5em;
	padding: .5em 1.5em;
	width: calc(100% - 6em);
	border-radius: .2em;
	width: auto;
}

.bt-close{
	position: relative;
	float: right;
	width: 28px;
	height: 28px;
	margin-left: calc(50% - 48px);
	fill:#353A42;
	background: white;
	margin: 10px;
}

.menu-mobile-bk{
	position: fixed;
	width: 100%;
	right: 0;
	height: 100vh;
	top: 0;
	z-index: 200;
	pointer-events: none;
	background: none;
	transition-property: all;
	transition-duration: .3s;
	transition-timing-function: cubic-bezier(0.55,0,0.1,1);
}

.menu-mobile {
	position: fixed;
	width: 80%;
	right: -90%;
	height: 100vh;
	background: white;
	top: 0;
	box-shadow: -1em 0 1em rgba(0, 0, 0, 0.5);
	z-index: 1;
	transition-property: all;
	transition-duration: .3s;
	transition-timing-function: cubic-bezier(0.55,0,0.1,1);
}

.menu-mobile-bk.on{
	background: linear-gradient(190deg,rgba(51, 197, 134, 0.7) 0%,rgba(81, 203, 255, 0.7) 100%);
	pointer-events:all;
}

.menu-mobile.on{
	right: 0;
}


@media only screen and (min-width: 46.001em)   { 
	.menu-mobile-bk, .menu-mobile{display: none;}
}