* {
	font: 500 16px/25px 'Maison Neue', sans-serif;
	list-style: none;
	text-decoration: none;
	margin: 0;
	padding: 0;
	text-align: left;
	border: 0;
	color: #AAAAAA;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	outline: none;
	outline: 0;
  }

.pages-consultas {
	background-image: linear-gradient(90deg, #FE6243 0%, #E33885 100%);
	background-size: 100% 620px;
	background-repeat: no-repeat;
	position: relative;
}

.pages-consultas:before {
	content: '';
	background: url('../../img/consultas/bg-logo.png') no-repeat top center;
	display: block;
	height: 620px;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.pages-consultas .header { background:none; }

.pages-consultas .box-1 {
	padding: 115px 0 174px;
	position: relative;
}

.pages-consultas .box-1	h1 {
	color: #FFF;
	font-size: 30px;
	line-height: 40px;
	margin-bottom: 50px;
	font-weight: 300;
	text-align: left;
}

@media only screen and (min-width: 46.001em)   { 
	.pages-consultas .box-1	h1 {text-align: center;}

}

.pages-consultas .box-1	.button {
	width: 250px;
	display: block;
	margin: 0 auto;
	color: #F2515F;
}
.pages-consultas .box-1	i {
	display: block;
	width: 21px;
	height: 21px;
	color: #FFF;
	text-align: center;
	position: absolute;
	left: 50%;
	margin-left: -10px;
	bottom: 30px;
	font-size: 21px;
}

.pages-consultas .box-1	i:before {
	display: block;
	-webkit-animation: pulse 1.5s infinite alternate;
	-moz-animation: pulse 1.5s infinite alternate;
	-animation: pulse 1.5s infinite alternate;
}

.pages-consultas .box-2 {
	padding: 93px 0;
}

.pages-consultas .box-2	.title { margin-bottom: 83px; }

.pages-consultas .box-2	.slick-list { overflow: initial !important; }

.pages-consultas .box-2	.list-boxes-white .slick-slide {
	 -webkit-transition: all 0.15s ease-out 0s;
  -moz-transition: all 0.15s ease-out 0s;
  -o-transition: all 0.15s ease-out 0s;
  transition: all 0.15s ease-out 0s;
	opacity: .24;
	display: block !important;
}

.pages-consultas .box-2	.list-boxes-white .slick-slide.slick-active { opacity: 1; }

.pages-consultas .box-2	.slick-arrow {
	width: 49px;
	height: 49px;
	display: block;
	background: rgba(20,23,27,0.18);
	border-radius: 100%;
	font-size: 20px;
	text-align: center;
	line-height: 56px;
	color: #FFF;
	position: absolute;
	top: 50%;
	margin-top: -25px;
	z-index: 3;
	cursor: pointer;
}
.pages-consultas .box-2	.slick-arrow:before {
	position: relative;
	display: block;
	line-height: 49px;
	width: 20px;
	margin: 0 auto;
}

.pages-consultas .box-2	.slick-arrow.icon-arrow-left-2 {left: 5px;}
.pages-consultas .box-2	.slick-arrow.icon-arrow-left-2:before { left: -2px; }
.pages-consultas .box-2	.slick-arrow.icon-arrow-right-2 {right: 5px;}
.pages-consultas .box-2	.slick-arrow.icon-arrow-right-2:before { right: -2px; }

.pages-consultas .box-2	.white-box {
	width: 300px;
	margin: 0 25px;
	padding: 40px 35px;
	border-radius: 3px;
	background: #FFF;
	box-shadow: 0 2px 41px rgba(0,0,0,0.08);
}
.pages-consultas .box-2	.white-box .header-white-box {
	display: block;
	margin-bottom: 20px;
}

.pages-consultas .box-2	.white-box .header-white-box:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
} 

/* .pages-consultas .box-2	.white-box .header-white-box * html & { height: 1px } */

.pages-consultas .box-2	.white-box .header-white-box i {
	float: left;
	width: 36px;
	height: 36px;
	border-radius: 100%;
	line-height: 42px;
	text-align: center;
	color: #FFF;
	background-image: linear-gradient(-134deg, #F56F5C 0%, #E04E90 91%);
	font-size: 18px;
	margin-right: 15px;
}

.pages-consultas .box-2	.white-box .header-white-box i:before {
	position: relative;
	left: 1px;
}
.pages-consultas .box-2 .white-box .header-white-box h2 {
	line-height: 36px;
	font-weight: 500;
	font-size: 16px;
	color: #353A42;
	letter-spacing: 0.57px;
	text-transform: uppercase;
}

.pages-consultas .box-2	.content-white-box p {
	font-size: 15px;
	line-height: 22px;
}

.pages-consultas .box-2	.content-white-box .button {
	display: inline-block;
	font-weight: 500;
	font-size: 13px;
	color: #E1508C;
	letter-spacing: 0.77px;
	line-height: 29px;
	height: 32px;
	border: 2px solid #E1508C;
	padding: 0 15px;
	margin: 40px 0 0;
}

.pages-consultas .box-2	.content-white-box .button:hover {
	background: #E1508C;
	color: #FFF;
}


.pages-consultas .box-3 {
	padding: 120px 0 80px 0;
}

.pages-consultas .box-3	.title {margin-bottom: 55px; }

.pages-consultas .box-3	.list-integration {
	max-width: 750px;
	margin: 0 auto;
}

.pages-consultas .box-3	.list-integration li{
	margin: 1.25em 0;
}

.pages-consultas .box-3	.list-integration li img {
	display: block;
	margin: 0 auto;
}

.pages-consultas .box-4 {
	padding: 0 0 100px 0;
}
@media only screen and (min-width: 46.001em)   { 
.pages-consultas .box-4 {
	padding: 100px 0;
}
}

.pages-consultas .box-4	.title { padding: 62px 0; }
.pages-consultas .box-4	.title	h2 { color: #FFF; }
.pages-consultas .box-4	.title	p { color: #FFF; }

.pages-consultas .box-4	.screen {
	max-width: 500px;
	margin: 0 auto;
}

.pages-consultas .box-4	.screen	.controls {
	margin: 17px 0 10px;
}

.pages-consultas .box-4	.screen	.controls ul {
	text-align: center;
	font-size: 0;
}

.pages-consultas .box-4	.screen	.controls li {
	display: inline-block;
	margin: 0 11px;
}

.pages-consultas .box-4	.screen	.controls li:first-child { margin-left: 0; }
.pages-consultas .box-4	.screen	.controls li:last-child { margin-right: 0; }

.pages-consultas .box-4	.screen	.controls li a {
	  display: block;
}

.pages-consultas .box-4	.screen	.controls li a:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
/* .pages-consultas .box-4	.screen	.controls li a * html & { height: 1px } */

.pages-consultas .box-4	.screen	.controls li a.active p { color: #FFF; }
.pages-consultas .box-4	.screen	.controls li a.active span {
	background: #FFF;
	color: #f87551;
}

.pages-consultas .box-4	.screen	.controls li a span {
	float: left;
	display: block;
	width: 26px;
	height: 26px;
	border: 2px solid #FFF;
	color: #FFF;
	border-radius: 100%;
	text-align: center;
	font-size: 15px;
	line-height: 22px;
	margin-right: 10px;
	 -webkit-transition: all 0.15s ease-out 0s;
  -moz-transition: all 0.15s ease-out 0s;
  -o-transition: all 0.15s ease-out 0s;
  transition: all 0.15s ease-out 0s;
}

.pages-consultas .box-4	.screen	.controls li a p {
	float: left;
	font-size: 13px;
	color: #FFF;
	line-height: 13px;
		 -webkit-transition: all 0.15s ease-out 0s;
  -moz-transition: all 0.15s ease-out 0s;
  -o-transition: all 0.15s ease-out 0s;
  transition: all 0.15s ease-out 0s;
	display: block;
	height: 25px;
	max-width: 80px;
}

.pages-consultas .box-4	.screen	.controls li a p.one-line { line-height: 25px; }


.pages-consultas .box-4	.screen .slide {
	border: 2px solid #FFF;
	position: relative;
	border-radius: 6px;
}

.pages-consultas .box-4	.screen .slide:before,
.pages-consultas .box-4	.screen .slide:after {
	content: '';
	width: 7px;
	height: 7px;
	display: block;
	background: #FFF;
	border-radius: 100%;
	position: absolute;
	left: 11px;
	top: 7px;
}

.pages-consultas .box-4	.screen .slide:after { left: 22px; }

.pages-consultas .box-4	.screen .slide .list-slide {
	border-top: 2px solid #FFF;
	margin-top: 21px;
	padding: 35px;
}

.pages-consultas .box-4	.screen .slide .list-slide code p {
	font-size: 15px;
	color: #FFF;
	letter-spacing: 1.21px;
	line-height: 18px;
	display: block;
	white-space: nowrap;
}

.pages-consultas .box-4	.screen .slide .list-slide code span {
	font-size: 15px;
	letter-spacing: 1.21px;
	line-height: 18px;
}

.pages-consultas .box-4	.screen .slide .list-slide code span.green { color: #4b515a; }


.pages-consultas .box-5 { padding: 100px 0; }

.pages-consultas .box-5  .title {
	margin: 0 auto;
}

@media only screen and (min-width: 46.001em)   { 
.pages-consultas .box-5  .title {padding: 133px 0;}
}

.pages-consultas .box-5 img{float: right !important;}

/*.pages-consultas .box-request-estimate {background: #e2508d;}*/

.pages-consultas .box-request-estimate form .button-bar .button { color: white; }

