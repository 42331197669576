* {
	font: 500 16px/25px 'Maison Neue', sans-serif;
	list-style: none;
	text-decoration: none;
	margin: 0;
	padding: 0;
	text-align: left;
	border: 0;
	color: #AAAAAA;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	outline: none;
	outline: 0;
  }
  
.pages-home {
	background-size: 100% 620px;
	background-repeat: no-repeat;
}

.pages-home .cover-panel{
	position: absolute;	
	width: 100%;
	height: 300px;
	background: linear-gradient(90deg,#33c586 0%,#51cbff 100%);
	top: 0;
}

.pages-home .cover{
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	transform: skewY(-8deg);
	transform-origin: 0;
	background: linear-gradient(190deg,#33c586 0%,#51cbff 100%);
}

.pages-home .header { background-image: none; }


.box-1 , .box-2, .box-3, .box-4, .box-5, .box-6, .box-7, .box-8, .box-9, .box-10, .box-11, .box-12{
	padding:2.5em 0;
}

@media only screen and (min-width: 46.001em) { 
	.box-1 , .box-2, .box-3, .box-4, .box-5, .box-6, .box-7, .box-8, .box-9, .box-10, .box-11, .box-12{
		padding:5.3em 0;
	}

	.box-2 {
		padding-top: 11em !important;
	}
}

.pages-home	.box-1 {
	position: relative;
}

.pages-home	.box-1 .title{
	position: relative;
	width: 90%;
}

.pages-home .box-1 figure,.pages-home .box-1 svg {
	position: absolute;
	right: -150px;
	height:430px;
	width: 770px;
	top: -3vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: auto 100% ;
	display: none;
}

.pages-home .box-1 svg tspan{font-size: 13px;}

@media only screen and (min-width: 46.001em)   { 
	.pages-home	.box-1 .title{width: 50%;}
	.pages-home	.box-1 {padding: 3.5em 0 4em 0;}
	.pages-home .box-1 figure{display: block;}
	.pages-home .box-1 svg{display: block;}
}


.pages-home	.box-1 h1, .pages-home	.box-1 h1 span {
	text-align: left;
	text-transform: uppercase;
	color: #FFF;
	padding-top: 1em;
    font-size: 36px;
    line-height: 45px;
    letter-spacing: 1px;
	margin-bottom: 1em;
	font-weight: 300;
}

@media only screen and (min-width: 46.001em) { 
	.pages-home .box-1 h1{
		line-height: 45px;
		font-size: 45px;
		padding-top: 0;
		margin-bottom: 18px;
	}
	.pages-home .cover{
		height: 580px;
		background: linear-gradient(90deg,#33c586 0%,#51cbff 100%);
	}
}

.pages-home	.box-1 p {
	display: block;
	text-align: left;
	font-size: 13.5px;
	color: #FFFFFF;
	line-height: 28px;
	opacity: .9;
	margin-bottom: 35px;
}

.pages-home	.box-1 .button {
	float: left;
	display: block;
	margin: 0 auto;
}

.pages-home .box-1 figure:before{
	content: '';
	background: url('../../img/home/dashboard.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: auto 100% ;
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.pages-home	.box-1 i {
	display: block;
	width: 21px;
	height: 21px;
	color: #FFF;
	text-align: center;
	position: absolute;
	left: 50%;
	margin-left: -10px;
	bottom: 30px;
	font-size: 21px;
	display: none;
}				
.pages-home	.box-1 i:before {
	display: block;
	-webkit-animation: pulse 1.5s infinite alternate;
	-moz-animation: pulse 1.5s infinite alternate;
	-animation: pulse 1.5s infinite alternate;
}

/* ==================================== box-2 ============================================================ */

.pages-home	.box-2 {
	background: #FFF;
	padding-bottom: 0;
}

.pages-home	.box-2	h2 {
	font-size: 16px;
	color: #353A42;
	letter-spacing: 1.29px;
	text-transform: uppercase;
	text-align: center;
}

.pages-home	.box-2	.list-customers {
	display: block;
	padding: 3em 0px 2.5em 0px;
	-webkit-transition: all 0.25s ease-out 0s;
	-moz-transition: all 0.25s ease-out 0s;
	-o-transition: all 0.25s ease-out 0s;
	transition: all 0.25s ease-out 0s;
	cursor: pointer;
}

.box-2 .list-customers ul {
	display: flex;
	justify-content: space-between;
	align-items: center;
	-webkit-transition: all 0.25s ease-out 0s;
	transition: all 0.25s ease-out 0s;
}

.box-2 .list-customers ul li {
	max-width: 16%;
}

.box-2 .list-customers img {
	width: 100%;
	height: auto;
}

.pages-home	.box-2 .list-customers span{
	display: block;
	position: relative;
	height: 48px;
	top: 15px;
	line-height: 48px;
	background: #00ccb0;
	border-bottom: 2px solid rgba(0,0,0,0.1);
	color: white;
	width: 200px;
	left: calc(50% - 100px);
	text-align: center;
	border-radius: .2em;
	pointer-events: all;
	box-shadow: 0 .2em .5em rgba(0,0,0,0.2);
	text-transform: uppercase;
	opacity: 0;
	pointer-events: none;
	-webkit-transition: all 0.25s ease-out 0s;
	-moz-transition: all 0.25s ease-out 0s;
	-o-transition: all 0.25s ease-out 0s;
	transition: all 0.25s ease-out 0s;
}

.pages-home	.box-2	.list-customers:hover ul.row{
	-webkit-transform: scale(.95);
	transform: scale(.95);
	-webkit-transition: all 0.25s ease-out 0s;
	transition: all 0.25s ease-out 0s;
	-webkit-filter: blur(10px);
	filter: blur(10px);
}

.pages-home	.box-2 .list-customers:hover span{
	opacity: 1;
	top: -50px;
	pointer-events: all;
}

@media only screen and (max-width: 46.000em) { 
	.box-2 .list-customers ul {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	.box-2 .list-customers ul li {
		margin: .5em;
	}
}

@media only screen and (min-width: 46.001em)   {
	

	.pages-home	.box-2	.list-customers	li.col-md-2 {
		width: 16.666666666%;
		padding-left: 15px;
		padding-right: 15px;
		margin: auto;
		height: 50px;
		display: -webkit-flex;
		display: -o-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: flex;
		-webkit-flex-direction: row;
		-o-flex-direction: row;
		-moz-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-o-flex-wrap: wrap;
		-moz-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-justify-content: flex-start;
		-o-justify-content: flex-start;
		-moz-justify-content: flex-start;
		-ms-justify-content: flex-start;
		justify-content: flex-start;
	}
}

.pages-home	.box-2	.list-customers	li img {
	display: block;
	margin: auto;
	max-width: 100%;
}

.pages-home	.box-3 h2 { text-align: center; }

.pages-home	.box-3 .title  p {
	text-align: center;
	display: block;
}

.pages-home	.box-3 img {
	display: block;
	width: 100%;
	margin: 40px auto auto;
}

@media only screen and (min-width: 46.001em)   { 
	.pages-home	.box-3 img {
		max-width: 750px;
		margin: 0 auto;
		margin-top: 3em;
	}
}

.try-now .box-mail-content svg.stop{
	animation: none;
}

.pages-home	.box-4	.title { margin-top: 62px; }

.pages-home	.box-4 img{margin-right: -55px;}

.pages-home	.box-4 .col-md-4.col-sm-4.col-xs-12{ margin-bottom: 3em;}

#VINDI tspan{font-size: 8.5px; font-weight: bold;}

.pages-home	.box-5 .col-md-5.col-sm-5.col-xs-12{ float: right; margin-bottom: 3em;}
.pages-home	.box-5 .col-md-7.col-sm-7.col-xs-12{float: left;}
.pages-home	.box-5 img{
	position: relative;
	float: left;
	width: 100%;
}

.pages-home	.box-4 img{position:relative; float:left;margin-left: -150px;}


@media only screen and (min-width: 46.001em)   {
	.pages-home	.box-5{
		/*padding: 90px 0;*/
	}

	.pages-home .box-4{
		/*padding: 120px 0 150px 0;*/
	}
	.pages-home	.box-5 img{width: 450px;}
}

.pages-home	.box-5 	.title { margin-top: 75px; }

.pages-home	.box-6	.title { margin-top: 25px; }
.pages-home	.box-6	svg { 
	width: 100%;
	margin-top: 2em;
}

.pages-home	.box-6	svg #multi-company tspan{
	font-size: 70px;
	font-family: 'Lora', serif;
	fill:#353A42;
	opacity: .6;
}
.pages-home	.box-6 #Oval-71{
	fill:#dddddd;

}

.pages-home	.box-6 #Oval-71 circle{
	stroke: white;
	stroke-width:2px;
	animation: explosion-point 2.5s infinite linear;
}



@media only screen and (min-width: 46.001em)   { 

	.pages-home	.box-6 {
		padding: 141px 0;
	}

	.pages-home	.box-6	svg { 
		float: right;
		margin-right: 50px; 
		width: auto;
		margin-top: 0;
	}
}

.svg-lines{
	stroke: #AAAAAA; 
	stroke-dasharray: 10;
	stroke-dashoffset: 10;
	animation: dash-progress 1s linear infinite;
}

.company-c{fill:#FDD835; animation: pulse 1.5s infinite alternate;}
.company-b{fill:#4CAF50; animation: pulse 2s infinite alternate;}
.company-a{fill:#29B6F6; animation: pulse 2.5s infinite alternate;}

.pages-home .box-7 .col-md-4.col-sm-4.col-xs-12 {
	float: right;
}
.pages-home .box-7 .col-md-8.col-sm-8.col-xs-12 {
	float: left;
}

.pages-home	.box-7 text tspan{font-size: 14px;}
.pages-home	.box-7	svg { 
	margin-left: -220px; 
	margin-top: 3.5em;
	width: 500px;
	height: auto;
}

@media only screen and (min-width: 46.001em)   { 
	.pages-home	.box-7 .col-md-8 .col-sm-8 .col-xs-12 {width: 50%;}
	.pages-home	.box-7	svg{ 
		width: auto;
		margin-top: 0;
	}
	.pages-home	.box-7{padding: 130px 0 59px;}
}

.pages-home .box-7 #Oval-6-Copy-4{
	stroke-width:2px; 
	stroke:#efb106;
	animation: explosion-point 3s infinite linear;
}

.timeline-dash{
	animation: dash-progress 2s linear infinite;
}

.pages-home	.box-8 {
	background: #FFF;
}

.pages-home	.box-8 .col-md-4{margin-top: 2.5em;}

@media only screen and (min-width: 46.001em)   { .pages-home	.box-8 .col-md-4{margin-top: 0;} }

.pages-home	.box-8 i, .pages-home .box-8 svg {
	font-size: 35px;
	width: 35px;
	height: 35px;
	margin: 0 calc(50% - 17.5px);
	color: #353A42;
	margin-bottom: 22px;
	display: block;
}

.pages-home	.box-8 svg g{fill:#353a42;}

.pages-home	.box-8 svg { width: 40px; margin: 0 calc(50% - 20px);margin-bottom: 22px; }

.pages-home	.box-8 i.icon-digital-certificate { font-size: 36px; }

.pages-home	.box-8	h4 {
	text-transform: uppercase;
	font-size: 18px;
	color: #353A42;
	letter-spacing: 1.45px;
	line-height: 25px;
	margin-bottom: 13px;
	text-align: center;
}

.pages-home	.box-8	p {
	display: block;
	line-height: 25px;
	padding-right: 0px;
	text-align: justify;
}

@media only screen and (min-width: 46.001em)   { 
	.pages-home	.box-8	h4, .pages-home	.box-8 p{text-align: left;}
	.pages-home	.box-8	i, .pages-home	.box-8 svg {margin: .5em 0;}
}

.pages-home	.box-9 {
	background: #2F353E;
}

.pages-home	.box-9 .cover{
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	transform: skewY(12deg);
	transform-origin: 0;
	background: #2F353E;
}

.pages-home	.box-9 .title h2 {
	text-align: center;
	color: #FFF;
}

.pages-home	.box-9 .title p {
	text-align: center;
	display: block;
	color: #76787C;
}

.svg-line-integration-in, .svg-line-integration-out{
	stroke-dasharray: 10;
	stroke-dashoffset: 10;
}

.svg-line-integration-in{
	animation: dash-progress 2s linear infinite;
	-webkit-transform: translateZ(1);
	-moz-transform: translateZ(1);
	-ms-transform: translateZ(1);
	-o-transform: translateZ(1);
	transform: translateZ(1);
}
.svg-line-integration-out{
	animation: dash-progress 2s linear reverse infinite;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
}

.pages-home	.box-9 svg {
	max-width: 100%;
	display: block;
	margin: 2em auto;
	width: 100%;
	left: 5%;
	height: auto;
}


.pages-home	.box-9 svg .center-pointer{
	animation: explosion-point 2.5s infinite linear;
	stroke: #5cc2e5;
	fill:#353A42;
	stroke-width: 2px;
	box-shadow: 0 1em 1em rgba(0,0,0,0.2);
}

@media only screen and (min-width: 46.001em)   { 
	.pages-home	.box-9 svg {
		width: auto;
		left: auto;
		margin: 4em auto;
	}
}

.pages-home	.box-9 svg text tspan{font-size: 14px;}

.pages-home	.box-10 {
	background: #353A42;
	display: none;
}

.pages-home	.box-10 .title { margin-top: 68px; }
.pages-home	.box-10 .title h2 { color: #FFF; }
.pages-home	.box-10 .title p { color: #787A7D; }

.pages-home	.box-10 .screen {
	max-width: 500px;
	margin: 0 auto;
}	

.pages-home	.box-10 .screen	.controls { margin: 17px 0 25px; height: 30px; }

.pages-home	.box-10 .screen	.controls ul {
	text-align: center;
	font-size: 0;
}

.pages-home	.box-10 .screen	.controls li {
	display: inline-block;
	margin: 0 !important;
	width: 25% !important;
	cursor: pointer;
}

.pages-home	.box-10 .screen	.controls li:first-child { margin-left: 0; }
.pages-home	.box-10 .screen	.controls li:last-child { margin-right: 0; }
.pages-home	.box-10 .screen	.controls li.slick-current p { color: #FFF; }
.pages-home	.box-10 .screen	.controls li.slick-current span {
	background: #43C36F;
	color: #FFF;
}

.pages-home	.box-10 .screen	.controls li span {
	float: left;
	display: block;
	width: 25px;
	height: 25px;
	background: #444850;
	border-radius: 100%;
	text-align: center;
	font-size: 15px;
	line-height: 26px;
	margin-right: 10px;
	-webkit-transition: all 0.15s ease-out 0s;
	-moz-transition: all 0.15s ease-out 0s;
	-o-transition: all 0.15s ease-out 0s;
	transition: all 0.15s ease-out 0s;
}

.pages-home	.box-10 .screen	.controls li p {
	float: left;
	font-size: 13px;
	color: #696C72;
	line-height: 13px;
	-webkit-transition: all 0.15s ease-out 0s;
	-moz-transition: all 0.15s ease-out 0s;
	-o-transition: all 0.15s ease-out 0s;
	transition: all 0.15s ease-out 0s;
	display: block;
	height: 25px;
	max-width: 80px;
}

.pages-home	.box-10 .screen	.controls li p.one-line { line-height: 25px; }


.pages-home	.box-10 .slide {
	border: 2px solid #4B515A;
	position: relative;
	border-radius:6px;
}

.pages-home	.box-10 .slide:before,
.pages-home	.box-10 .slide:after {
	content: '';
	width: 7px;
	height: 7px;
	display: block;
	background: #4B515A;
	border-radius:100%;
	position: absolute;
	left: 11px;
	top: 7px;
}

.pages-home	.box-10 .slide:after { left: 22px; }

.pages-home	.box-10 .slide .list-slide {
	border-top: 2px solid #4B515A;
	margin-top: 21px;
	/*padding: 35px;*/
}

.pages-home	.box-10 .slide .list-slide li {
	position: relative;
	display: block;
}


.pages-home	.box-10 .slide .list-slide code span.green { color: #4CC89A; }

.pages-home	.box-11 {
	overflow: hidden;
	background: #30353E url('../../img/home/im-box-11-cut.png') no-repeat top center;
}

@media only screen and (min-width: 46.001em)   { 
	.pages-home	.box-11 {
		background:	#30353E url('../../img/home/im-box-11-cut.png') no-repeat left center;
	}
}

.pages-home	.box-11 .title {
	margin-top: 18px;
}
.pages-home	.box-11  .title h2 {
	color: #FFF;
	margin-bottom: 14px;
}
.pages-home	.box-11  .title p {
	display: block;
	color: #737679;
}
.pages-home	.box-11  img {
	margin-left: 60px;
	width: 90%;
}

.pages-home	.box-12	.title h2 { text-align: center; }
.pages-home	.box-12	.title  p {
	text-align: center;
	display: block;
}

.pages-home	.box-12	svg {
	max-width: 100%;
	display: block;
	margin: 45px auto 0;
	height: auto;
}

.pages-home	.box-12	svg #path-11{
	animation: explosion-point 2.5s infinite linear;
	stroke: #5cc2e5;
	stroke-width: 2px;
}

.pages-home	.box-12	svg path{
	stroke-dasharray: 10;
	stroke-dashoffset: 10;
	/*fill:#5cc2e5;*/
	animation: dash-progress 1s linear infinite;
}

.pages-home	.box-prices .view-more a {
	color: #51cbff;
	border-bottom: 1px dotted #51cbff;
}

.box-contact{
	position: relative;
	float: left;
	width: 100%;
	min-height: 550px;
	padding: 4em 0;
}


.box-contact .title{
	position: absolute;
	top: calc(50% - 100px);
	float: left;
	margin: 0 1em;
	width: calc(100% - 2em);
	background: white;
	padding: 2em;
	border-radius: .2em;
	box-shadow: 0 1em 1em rgba(0,0,0,0.2);
	border:1px solid rgba(0,0,0,0.1);
}

.box-contact .title p{text-align: center;}

.box-contact h2{
	text-align: center;
}

.box-contact:before{
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: url('https://api.mapbox.com/styles/v1/nfe/cj7m35far59rg2rmykwsdlxfo/static/-46.687831,-23.560873,17.50,0.00,25.00/1280x800@2x?access_token=pk.eyJ1IjoibmZlIiwiYSI6ImNqN20wbWlzcTJ0ajUzM25wem5pbWM5a24ifQ.YPLD-cUyy1YUdOL-imLPaA');
	background-position: center 90%;
	background-repeat: no-repeat;
	background-size: auto 150%;
}


@media only screen and (min-width: 46.001em)   { 
	.pages-home	.box-11  img {
		width: initial;
	}

	.box-contact .title{
		margin: 0 calc(50% - 250px) !important;
		width: 500px !important;
		top: calc(50% - 80px);
	}
	.box-contact:before{
		background: url('https://api.mapbox.com/styles/v1/nfe/cj7m35far59rg2rmykwsdlxfo/static/-46.687831,-23.560873,17.50,0.00,25.00/1280x800@2x?access_token=pk.eyJ1IjoibmZlIiwiYSI6ImNqN20wbWlzcTJ0ajUzM25wem5pbWM5a24ifQ.YPLD-cUyy1YUdOL-imLPaA');
		background-position: center 60%;
		background-repeat: no-repeat;
		background-size: 100% auto;
	}
}

