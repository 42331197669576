.pages-integration .box-1{
	position: relative;
}

.pages-integration .box-1 h1, .pages-integration .box-1 .title p{
	position: relative;
	color: white;
	text-align: center;
}

.pages-integration	.box-1 h1 {
	text-align: center;
	text-transform: uppercase;
	color: #FFF;
	font-size: 40px;
	letter-spacing: 1px;
	line-height: 40px;
	margin-bottom: 18px;
	font-weight: 300;
}

.pages-integration .cover{
	position: relative;
	float: left;
	width: 100%;
	top: -100px;
	height: 100%;
	padding: 12em 0 3em 0;
	background: #5f357d;
    background: -webkit-radial-gradient(circle farthest-corner at right bottom,#ffd08a 0,#ffa376 28%,#d26578 52%,#682a84 79%,#241668 100%);
    background: radial-gradient(circle farthest-corner at right bottom,#ffd08a 0,#ffa376 28%,#d26578 52%,#682a84 79%,#241668 100%);
}

.row-integrations{
	position: relative;
	float: left;
	bottom: -150px;
	padding: 0 20px;
	display: -webkit-flex;
    display: -o-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -o-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -o-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
}

.row-integrations a{
	position: relative;
	float: left;
    max-width: 30%;
    background-color: white;
    border-radius: .2em;
    min-height: 220px;
    width: auto;
    margin: auto;
    box-shadow: 0 18px 35px rgba(50,50,93,.1), 0 8px 15px rgba(0,0,0,.07);
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(0.55,0,0.1,1);
    transform: translateY(-2px);
}

.row-integrations a img{
	position: relative;
	float: left;
	background-color: white;
	border-radius: 50%;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    display: inline-block;
    width: 100px;
    height: 100px;
    margin-top: -45px;
    margin-bottom: 20px;
    margin: -45px calc(50% - 50px) 20px calc(50% - 50px);
}
.row-integrations a p{
	color: #AAAAAA;
	padding: 0 1.5em;
	text-align: justify;
}
.row-integrations a p.name{
	color: #353A42;
	font-size: 18px;
	text-align: center;
	margin-bottom: .5em;

}

.row-integrations a:hover{
	transform: translateY(0);
}
.pages-integration .box-2{
	position: relative;
	float: left;
	width: 100%;
	padding: 6em 0;
}

.pages-integration	.box-2 .addon{
	position: relative;
	float: left;
	width: 30%;
	max-width: 250px;
	margin: 2em auto;
}

.pages-integration .box-2 h2, .pages-integration .box-2 .title p{
	text-align: center;
}

.pages-integration .box-2 .title{margin-bottom: 5em;}

.pages-integration	.box-2 .addon figure{
	position: relative;
	float: left;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: -webkit-flex;
    display: -o-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -o-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -o-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    background-color: #eee;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.pages-integration	.box-2 .addon img{
	position: relative;
	float: left;
	height: auto;
	width: auto;
	max-width: 90%;
	max-height: 90%;
	margin: auto;
}

.pages-integration .box-2 .addon p{
	position: relative;
	float: right;
	width: calc(100%  - 70px);
	line-height: 18px;
}

.pages-integration .box-2 .addon p.name{
	font-weight: 500;
	color: #353A42;
	margin-top: .6em;
}

.pages-integration .box-2 .list-addons{
	position: relative;
	float:left;
	display: -webkit-flex;
    display: -o-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -o-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -o-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: auto;
}

.pages-integration footer{
	position: relative;
	float: left;
	width: 100%;
}