@keyframes dash {

	0% {
		stroke-dashoffset: 0;
		-moz-transform:scale(1);
		-webkit-transform:scale(1);    
		-ms-transform:scale(1);
		-o-transform:scale(1);
		transform:scale(1);
	}

	25% {
		stroke-dashoffset: -100;
		-moz-transform:scale(.9);
		-webkit-transform:scale(.9);    
		-ms-transform:scale(.9);
		-o-transform:scale(.9);
		transform:scale(.9);
	}

	40%{
		-moz-transform:scale(.9);
		-webkit-transform:scale(.9);    
		-ms-transform:scale(.9);
		-o-transform:scale(.9);
		transform:scale(.9);
	}

	45%{
		-moz-transform:scale(1);
		-webkit-transform:scale(1);    
		-ms-transform:scale(1);
		-o-transform:scale(1);
		transform:scale(1);
	}

	50% {
		stroke-dashoffset: -200;
		-moz-transform:scale(1);
		-webkit-transform:scale(1);    
		-ms-transform:scale(1);
		-o-transform:scale(1);
		transform:scale(1);
	}

	55%{
		-moz-transform:scale(.9);
		-webkit-transform:scale(.9);    
		-ms-transform:scale(.9);
		-o-transform:scale(.9);
		transform:scale(.9);
	}


	75% {
		stroke-dashoffset: -100;
		-moz-transform:scale(1);
		-webkit-transform:scale(1);    
		-ms-transform:scale(1);
		-o-transform:scale(1);
		transform:scale(1);
	}

	100% {
		stroke-dashoffset: 0;
		-moz-transform:scale(1);
		-webkit-transform:scale(1);    
		-ms-transform:scale(1);
		-o-transform:scale(1);
		transform:scale(1);
	}

}

.box-testimonials {
	overflow: hidden;
	padding-top: 3em;
	background: #b71c1c; /* Old browsers */
	background: -moz-linear-gradient(45deg, #b71c1c 0%, #ef5350 49%, #c62828 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, #b71c1c 0%,#ef5350 49%,#c62828 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, #b71c1c 0%,#ef5350 49%,#c62828 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b71c1c', endColorstr='#c62828',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.box-testimonials .c{
	position: absolute;
	width: 80%;
	left: 10%;
	margin-top: 5%;
	height: 800px;
	overflow: hidden;
	transform: skewY(-12deg);
	transform-origin: 0;
	background: #b71c1c; 
	box-shadow: 0 1em 1em rgba(0,0,0,0.1);
	background: -moz-linear-gradient(45deg, #b71c1c 0%, #ef5350 49%, #c62828 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, #b71c1c 0%,#ef5350 49%,#c62828 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, #b71c1c 0%,#ef5350 49%,#c62828 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b71c1c', endColorstr='#c62828',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	display: none;
}


.box-testimonials .love{
	position: relative;
	float: left;
	width: 8em;
	height: 8em;
	margin: 2em calc(50% - 4em);
	fill: none;
	stroke: #AAAAAA;
	stroke: white;
	opacity: 1;
	stroke-width: .25;
	stroke-dasharray: 100;
	stroke-dashoffset: 100;
	animation: dash 8s linear infinite alternate ;
}

.box-testimonials .title {
	margin-bottom: 55px;
}
.box-testimonials .title h2 {
	text-align: center;
	margin-bottom: 14px;
	color: white;
}
.box-testimonials .title p {
	text-align: center;
	display: block;
	color: white;
}


.box-row-testimonials {
	position: relative;
	float: left;
	left: auto;
	width:100%;
	margin-bottom: 3em;
}

.box-testimonials .item{
	position: relative;
	display: block;
	padding: 1em 2em;
	width: 100%;
	height: auto;
	margin: 1em auto;
	float: left;
	border-radius: .2em;
	min-height:auto;
	background-color: white;
	box-shadow: 0 .6em .5em rgba(0,0,0,0.12);

}

.box-testimonials .arrow{
	position: absolute;
	left: -20px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	box-shadow: -0.1em 0.5em 1em rgba(0,0,0,0.1);
	top: calc(50% - 20px);
	cursor: pointer;
	fill:white;
	z-index: 1;
	background: rgb(241,241,241);
	fill:#b71c1c;
	-webkit-transition: all 0.25s ease-out 0s;
	-moz-transition: all 0.25s ease-out 0s;
	-o-transition: all 0.25s ease-out 0s;
	transition: all 0.25s ease-out 0s;
}

.box-testimonials .arrow:hover{
	box-shadow: 0 .5em 1em rgba(0,0,0,0.5);
	background: rgb(248,248,248);
}


.box-testimonials .arrow.rigth{
	right: -20px;
	left: auto;
}

.box-testimonials .arrow svg{
	position: relative;
	float: left;
	width: 24px;
	height: 24px;
	margin: 8px;
}

@media only screen and (min-width: 46.001em)   { 

	.box-testimonials .arrow{
		background: rgba(255,255,255,0.2); 
		fill:white;
	}

	.box-testimonials .arrow:hover{
		background: rgba(255,255,255,0.25); 
	}
	.box-testimonials .arrow:action{
		background: rgba(255,255,255,0.4); 
	}

	.box-row-testimonials{
		position: relative;
		float: left;
		left: 2em;
		width: calc(100% - 4em);
		padding: 2em;
		margin-bottom: 2em;
		display: -webkit-flex;
		display: -o-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: flex;
		-webkit-flex-direction: row;
		-o-flex-direction: row;
		-moz-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-o-flex-wrap: wrap;
		-moz-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-justify-content: flex-start;
		-o-justify-content: flex-start;
		-moz-justify-content: flex-start;
		-ms-justify-content: flex-start;
		justify-content: flex-start;
	}

	.box-testimonials .item{
		position: relative;
		display: block;
		padding: 1em 2em;
		width: calc(400px - 4em);
		height: auto;
		margin: 1em .5em;
		float: left;
		border-radius: .2em;
		min-height: 350px;
		background-color: white;
	}
}


.box-testimonials .item img{
	position: relative;
	float: left;
	width: 40px;
	height: 40px;
	border-radius: 50%;

}

.box-testimonials .item img.company{
	position: relative;
	float: left;
	width: auto;
	height: 60px;
	top: auto;
	margin:1.5em calc(100% - 90px) 1.5em 0;
	border:none;
	border-radius:0;
	bottom: auto;
	left: auto;
}
.box-testimonials .item img.company.vertex{color: #f71963;}
.box-testimonials .item img.company.wide{
	height: 60px;
	width: auto;
}

.box-testimonials .item p{
	position: relative;
	float: left;
	width: 100%;
	line-height: auto;
	font-style: italic;
	font-weight: 400;
}

.box-testimonials .item p.testimonials{
	min-height: 180px;
	margin-bottom: 1em;
}

.box-testimonials .item p.name{
	position: relative;
	float: right;
	font-weight: 400;
	color: #353A42;
	margin-top: .4em;
	font-style: normal;
	width: calc(100% - 50px);
}

.box-testimonials .item p.company{
	position: relative;
	float: left;
	padding: .2em .5em;
	border-radius: 24px;
}
