.arrow-down-content {
    display: none;
    position: absolute;
    z-index: 100;
    left: calc(50vw - 14px);
    top: 92vh;
}

.arrow-down-content .fa-arrow-down {
    color: #4C4C4C;
}

@media (max-height: 680px) {
    .arrow-down-content {
        display: block;
    }

    .bounce {
        animation: bounce 2s infinite;
    }

    @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(-30px);
        }
        60% {
            transform: translateY(-15px);
        }
    }
}

@media (min-height: 681px) {
    .arrow-down-content {
        display: none !important;
    }
}

@media (max-width: 767px) {
    .arrow-down-content {
        display: none !important;
    }
}
