.box-request-estimate {
	/*background-color: #00ccb0;*/
	padding: 8em 0;
}

.box-request-estimate .title {
	margin-bottom: 55px;
}
.box-request-estimate .title h2 {
	text-align: center;
}

.box-request-estimate form {
	max-width: 478px;
	margin: 0 auto;
}

.box-request-estimate .title p {
	text-align: center;
	display: block;
}

form .button-bar .button{color: white !important; background: #00ccb0; }