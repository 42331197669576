.button {
    display: inline-block;
    padding: 0 25px;
    height: 50px;
    line-height: 55px;
    font: 500 16px/50px 'Maison Neue';
    font-size: 18px;
    color: #3ecf8e;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-transition: all 0.15s ease-out 0s;
    -moz-transition: all 0.15s ease-out 0s;
    -o-transition: all 0.15s ease-out 0s;
    transition: all 0.15s ease-out 0s;
    background: #FFF;
    box-shadow: 0 .2em .4em rgba(0,0,0,0.15);
}

.button--primary {
    width: auto;
    margin-top: 22px;
    padding: 0 20px;
    background-color: #00ccb0;
    color: #ffffff;
}